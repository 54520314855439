<template>

  <div>

    <b-row class="match-height">
      <!-- <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col> -->
      <b-col
        xl="12"
        md="12"
      >
        <!-- <ecommerce-statistics :data="data.statisticsItems" /> -->
        <general-stats></general-stats>

      </b-col>
    </b-row>

    <b-card>

      <b-tabs
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="PlusIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Crear Pedido</span>
          </template>
          <create-order-card
            class="mt-2 pt-75"
          />
        </b-tab>
        
      </b-tabs>
    </b-card>


  </div>
  
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import CreateOrderCard from './CreateOrderCard.vue'
import GeneralStats from '../../statistics/general/GeneralStats.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    GeneralStats,
    CreateOrderCard,
  },
  setup() {
    
  },
}
</script>

<style>

</style>
